import React from "react";
import Layout from "../components/Layout";
import HeroContent from "../components/HeroContent";
import { window } from "browser-monads";
import "../styles/global.css";
import "../styles/index.css";
import foam from "../images/foam.jpg";
import windturbine from "../images/windturbine.jpg";
import wildfire from "../images/wildfire.jpg";
import tanker from "../images/tanker.jpg";
import largeTank from "../images/large-tank.jpg";
import sabotage from "../images/sabotage.jpg";

const media = [
  {
    title: "Biodegradable Foams",
    image: foam,
    component: React.lazy(() => import("../components/content/Biofoams")),
  },
  {
    title: "Extinguishing Wind Turbine Fires",
    image: windturbine,
    component: React.lazy(() => import("../components/content/WindTurbine")),
  },
  {
    title: "Fighting Wildfires",
    image: wildfire,
    component: React.lazy(() => import("../components/content/WildFires")),
  },
  {
    title: "Tanker Ship Fires",
    image: tanker,
    component: React.lazy(() => import("../components/content/TankerFire")),
  },
  {
    title: "Large-Diameter Hydrocarbon-Storage Fire Solutions",
    image: largeTank,
    component: React.lazy(() => import("../components/content/StoreFire")),
  },
  {
    title: "Protection for Installments in Conflict Zones",
    image: sabotage,
    component: React.lazy(() => import("../components/content/ConflictZone")),
  },
];

const IndexPage = () => {
  const iframeRef = React.useRef(null);
  const sectionRef = React.useRef(null);

  const iframeHandler = (entries) => {
    if (entries[0].isIntersecting) {
      iframeRef.current.src = iframeRef.current.getAttribute("data-src");
      observer.unobserve(iframeRef.current);
    }
  };

  const observer = new window.IntersectionObserver(iframeHandler);

  React.useEffect(() => {
    observer.observe(iframeRef.current);
  }, [observer]);

  return (
    <Layout>
      <main>
        <section className="landing__container">
          <HeroContent sectionRef={sectionRef} media={media} />
        </section>
        <section ref={sectionRef} className="video__section">
          <h2>
            Revolutionize fire safety in the hydrocarbon industry - the
            Pressurized Instant (Pi/𝛑) Foam System with AI. Large storage tank
            and dike fire extinguishing in 𝛑 (3.14) minutes.
          </h2>
          <div className="video__wrapper">
            <iframe
              ref={iframeRef}
              data-src="https://player.vimeo.com/video/325744124?autoplay=0&amp;autopause=0"
              allowFullScreen
              title="vimeo325744124"
              allow="autoplay; fullscreen"
            />
          </div>
          <button
            onClick={() =>
              window.open("https://vimeo.com/263745508/41a9d42d26")
            }
          >
            Same Video With Subtitles
          </button>
          <button onClick={() => window.open("https://pifoam.ch/")}>
            REDIRECT TO PIFOAM SYSTEM WEBSITE
          </button>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
