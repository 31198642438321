import React, { Suspense, useEffect, useState } from "react";
import { window } from "browser-monads";
import { to as scroll } from "zenscroll";
import Card from "./Card";
import arrow from "../images/arrow-down.svg";
import LoadingSpinner from "./LoadingSpinner";

function HeroContent({ media = [], sectionRef }) {
  const [state, setState] = useState({ open: false, index: 0 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state]);

  function extract() {
    const Component = media[state.index].component;
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <button onClick={() => setState((prev) => ({ ...prev, open: false }))}>
          Go Back
        </button>
        <button onClick={() => window.open("https://pifoam.ch")}>
          PI Foam System
        </button>
        <Component />
      </Suspense>
    );
  }

  return (
    <div className="hero">
      {!state.open && (
        <>
          <h1>
            Research, evaluation, and improvement of industrial fire prevention,
            and protection.
          </h1>
          <h2 style={{ color: "#e10707" }}>100% safety, 0% compromise</h2>
        </>
      )}
      {!state.open && (
        <div className="landing__cards">
          {media.map((card, i) => (
            <Card
              key={i}
              title={card.title}
              image={card.image}
              onClick={() => setState({ open: true, index: i })}
            />
          ))}
        </div>
      )}
      <div className="landing__content">{state.open && extract()}</div>
      <img
        onClick={() => scroll(sectionRef.current)}
        className="landing__arrow"
        src={arrow}
        alt="arrow down icon"
      />
    </div>
  );
}

export default HeroContent;
